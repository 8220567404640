@font-face {
  font-family: Blinker;
  font-weight: 300;
  src: url("./fonts/blinker/Blinker-Light.ttf");
}
@font-face {
  font-family: Blinker;
  font-weight: 500;
  src: url("./fonts/blinker/Blinker-SemiBold.ttf");
}
@font-face {
  font-family: Blinker;
  font-weight: 700;
  src: url("./fonts/blinker/Blinker-Bold.ttf");
}
@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url("./fonts/roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url("./fonts/roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: Saira;
  font-weight: 400;
  src: url("./fonts/saira/Saira-Regular.ttf");
}
@font-face {
  font-family: Saira;
  font-weight: 900;
  src: url("./fonts/saira/Saira-ExtraBold.ttf");
}
@font-face {
  font-family: Saira;
  font-weight: 200;
  src: url("/static/fonts/saira/Saira-Light.ttf");
}
